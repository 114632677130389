import React from 'react';
import { Link } from "gatsby";

// Shape Images
import BackgroundImage from "../../assets/images/kinetik/Hintergrund.png";


const MainBanner = () => {
    return (
        <div className="main-banner">

	    </div>
    )
}

export default MainBanner;  
import React from 'react';
import * as Icon from 'react-feather';

// Cloud Hosting Services Images
import BookSelf from "../../assets/images/services-right-image/book-self.png";
import Box from "../../assets/images/services-right-image/box.png";
import Chair from "../../assets/images/services-right-image/chair.png";
import Cloud from "../../assets/images/services-right-image/cloud.png";
import Cup from "../../assets/images/services-right-image/cup.png";
import FlowerTop from "../../assets/images/services-right-image/flower-top.png";
import HeadPhone from "../../assets/images/services-right-image/head-phone.png";
import Monitor from "../../assets/images/services-right-image/monitor.png";
import Mug from "../../assets/images/services-right-image/mug.png";
import Table from "../../assets/images/services-right-image/table.png";
import Tissue from "../../assets/images/services-right-image/tissue.png";
import WaterBottle from "../../assets/images/services-right-image/water-bottle.png";
import Wifi from "../../assets/images/services-right-image/wifi.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";
import ServiceRightMainPic from "../../assets/images/services-right-image/service-right-main-pic.png"; 

// Design & Development Images
import BigMonitor from "../../assets/images/services-left-image/big-monitor.png";
import Creative from "../../assets/images/services-left-image/creative.png";
import Developer from "../../assets/images/services-left-image/developer.png";
import SmallMonitor from "../../assets/images/services-left-image/small-monitor.png";
import SmallTop from "../../assets/images/services-left-image/small-top.png";
import Target from "../../assets/images/services-left-image/target.png";
import ServiceLeftMainPic from "../../assets/images/services-left-image/service-left-main-pic.png";

//Logos
import Allianz from "../../assets/images/kinetik/Allianz-logo.png";
import Avery from"../../assets/images/kinetik/Avery-logo.png"
import CSU from "../../assets/images/kinetik/CSU-logo.png"
import DECOR from "../../assets/images/kinetik/Decor-logo.png"
import DSV from "../../assets/images/kinetik/DSV-logo.png"
import Post from "../../assets/images/kinetik/Post-logo.png"
import brillenglas from "../../assets/images/kinetik/Glas-logo.png"
import TYROLIT from "../../assets/images/kinetik/tyrol-logo.png"
const ServicesArea = () => {
    return (
        <>
            <div className="services-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 services-content">
                            <div className="section-title">
                                <h2>Referenzen</h2>
                                <div className="bar"></div>
                                <p>Wir streben eine langfristige Partnerschaft mit unseren Kunden an. Viele schenken uns bereits seit über 10 Jahren Ihr Vertrauen..</p>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6">     
                              		<div className="box img-box">
                                  		<div className="img"><img src={Allianz} /></div> 
                                  		<div>Allianz</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    	<div className="box img-box">
                                  		<div className="img"><img src={Avery} /></div> 
                                  		<div>Avery Zwerkform</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box img-box">
                                  		<div className="img"><img src={CSU} /></div> 
                                  		<div>Csu</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                   	<div className="box img-box">
                                  		<div className="img"><img src={DECOR} /></div> 
                                  		<div>DECOR-UNION</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                   	<div className="box img-box">
                                  		<div className="img"><img src={DSV} /></div> 
                                  		<div>DSVGruppe</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box img-box">
                                     	<div className="img"><img src={Post} /></div> 
                                  		<div>Die Post</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box img-box">
                                      	<div className="img"><img src={brillenglas} /></div> 
                                  		<div>brillenglas.de</div>   
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="box img-box">
                                        	<div className="img"><img src={TYROLIT} /></div> 
                                  		<div>TYROLIT</div>   
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 services-right-image">
                            <img 
                                src={BookSelf}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="book-self"
                            />
                           
                            <img 
                                src={Box}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                data-wow-delay="0.6s" 
                                alt="box"
                            />
                         
                            <img 
                                src={Chair}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s" 
                                alt="chair"
                            /> 
                            
                            <img 
                                src={Cloud}
                                className="animate__animated animate__zoomIn animate__delay-0.2s" 
                                alt="cloud"
                            />
                   
                            <img 
                                src={Cup}
                                className="animate__animated animate__bounceIn animate__delay-0.2s" 
                                alt="cup"
                            />
               
                            <img 
                                src={FlowerTop}
                                className="animate__animated animate__fadeInDown animate__delay-0.2s" 
                                alt="flower"
                            />
                     
                            <img 
                                src={HeadPhone}
                                className="animate__animated animate__zoomIn animate__delay-0.2s" 
                                alt="head-phone"
                            />
                  
                            <img 
                                src={Monitor}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="monitor"
                            />
                
                            <img 
                                src={Mug}
                                className="animate__animated animate__rotateIn animate__delay-0.2s" 
                                alt="mug"
                            />
           
                            <img 
                                src={Table}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="table"
                            />
                    
                            <img 
                                src={Tissue}
                                className="animate__animated animate__zoomIn animate__delay-0.2s" 
                                alt="tissue"
                            />
                   
                            <img 
                                src={WaterBottle}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="water-bottle"
                            />
                
                            <img 
                                src={Wifi}
                                className="animate__animated animate__fadeInLeft animate__delay-0.2s" 
                                data-wow-delay="0.6s" 
                                alt="wifi"
                            />                      
                            <img 
                                src={CercleShape}
                                className="bg-image rotateme" 
                                alt="shape"
                            />
                         
                            <img 
                                src={ServiceRightMainPic}
                                className="animate__animated animate__fadeInUp animate__delay-0.2s" 
                                alt="main-pic"
                            />
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default ServicesArea;
import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

const Features = () => {
    return (
        <div className="boxes-area">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-4 col-sm-6">
						<div className="single-box height-100">
						
							<div className="icon">
                                <Icon.Server />
							</div>

							<h3>
								<Link to="/">
									Projektentwicklung
								</Link>
							</h3>
                            <b>Cloud-Native Java & Html</b>
							<p>Unsere Lösungen sind hoch- verfügbar und skalierbar, laufen im Webbrowser auf dem Desktop und Mobil und sind von früheren Desktop-Applikationen kaum noch zu unterscheiden.</p>
						</div>
					</div>

					<div className="col-lg-4 col-sm-6">
						<div className="single-box bg-f78acb height-100">
							<div className="icon">
                                <Icon.Code />
							</div>

							<h3>
								<Link to="/">
									Automatisierung und Web2Print
								</Link>
							</h3>
							<b> Prozess-Automatisierung</b>
							<p>Wir automatisieren den gesamten Herstellungsprozess von Layouts aller Art und benötigen dazu nur einen Webbrowser oder ein Mobil-Gerät.</p>
						</div>
					</div>

					<div className="col-lg-4 col-sm-6">
						<div className="single-box bg-c679e3 height-100">
							<div className="icon">
                                <Icon.Users />
							</div>

							<h3>
								<Link to="/">
									Operations
								</Link>
							</h3>
							<b> Hosting mit Performance </b>
							<p>Wir nutzen Cloud-Technologie, betreiben unsere Hardware aber selbst und erfüllen die Anforderungen unserer Kunden damit besser, als die bekannten Cloud-Anbieter.</p>
						</div>
					</div>
					
				</div>
			</div>
		</div>
    )
}

export default Features;
